<template>
  <div style="width: 100%; height: 100%; position: relative">
    <header>
      <p>{{ justWatch == 2 ? "全景查看" : "全景编辑" }}</p>
      <div>
        <span @click="saveBtn"
              v-if="justWatch == 1"
              class="btn">保存</span>
        <span @click="del"
              v-if="id != $route.query.id && justWatch == 1"
              style="background: #f56c6c; color: #fff">删除</span>
        <span @click="shareModel = true"
              v-if="justWatch == 2">分享</span>
        <span @click="back"
              :style="{ color: $store.state.darkTheme ? '#fff' : '#000' }">返回</span>
      </div>
    </header>
    <main>
      <!-- 左侧边栏 -->
      <!-- <nav></nav> -->
      <section>
        <div class="title"
             v-show="!editTitle"
             title="双击修改全景名"
             @dblclick="justWatch == 1 ? (editTitle = true) : ''">
          {{ caption }}
        </div>
        <Input class="title"
               v-show="editTitle"
               v-model="caption"
               maxlength="15"
               show-word-limit
               @on-enter="updateTitle"
               @on-blur="updateTitle"
               placeholder="请输入全景图名称" />
        <!-- 全景图容器 -->
        <div id="viewer"></div>
        <!-- 场景 -->
        <footer>
          <div>
            <div v-for="i in categoryList"
                 :class="[
                'panorama-list',
                i.id == id ? 'panorama-list-active' : '',
              ]"
                 @click="switchView(i.id)"
                 :title="i.name"
                 :key="i.id">
              <img :src="i.panoramaImgPath" />
            </div>

            <Upload name="fileUpload"
                    v-if="justWatch == 1 && !isFinish && !isFinish2"
                    :max-size="20480"
                    :on-exceeded-size="handleMaxSize"
                    :action="MediaUrl"
                    :data="upLoadFileData"
                    :format="['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG']"
                    :on-format-error="handleFormatError"
                    :on-success="addMediaSuccess"
                    :on-progress="onProgress"
                    :on-error="addMediaError"
                    :show-upload-list="false">
              <div class="upload">
                <Icon type="md-add"
                      size="30" /><br />添加场景
              </div>
            </Upload>
            <div class="upload"
                 v-if="justWatch == 1 && (isFinish || isFinish2)">
              <Progress :percent="percent"
                        v-if="isFinish || isFinish2"
                        :stroke-width="10"
                        style="width: 68px; height: 20px"
                        status="active"
                        hide-info />
              <span>{{ isFinish2 ? "正在解析" : "上传中" }}</span>
            </div>
          </div>
        </footer>
      </section>
      <!-- 标注 -->
      <aside>
        <!-- 列表 -->
        <div v-if="!inset"
             class="inset-list">
          <div class="inset-list-btnbox"
               v-if="justWatch == 1">
            <div class="btn start-inset"
                 @click="handleViewerClick">
              嵌入内容
            </div>
          </div>
          <div class="inset-lists">
            <p v-if="markers.length > 0">当前场景嵌入内容</p>
            <p v-else
               style="text-align: center">当前场景暂无嵌入内容</p>

            <div v-for="(item, index) in markers"
                 :key="index"
                 @click="selectMark(item)">
              <img v-if="item.markerTypeMark == 1"
                   src="@/assets/img/statistics/文字.png"
                   alt="" />
              <img v-if="item.markerTypeMark == 2"
                   src="@/assets/img/statistics/图片.png"
                   alt="" />
              <img v-if="item.markerTypeMark == 3"
                   src="@/assets/img/statistics/热点.png"
                   alt="" />
              <span>
                {{ item.markerTypeMark == 1 ? item.html : "" }}
                {{ item.markerTypeMark == 2 ? item.tooltip : "" }}
                {{ item.markerTypeMark == 3 ? item.html2 : "" }}</span>
            </div>
          </div>
        </div>
        <!-- 嵌入 -->
        <div class="inset"
             v-else-if="inset">
          <div>
            <p v-show="!onMarks">嵌入类型</p>
            <Select v-model="markType"
                    v-show="!onMarks"
                    @on-change="markIconType = 0"
                    style="width: 230px; margin: 10px auto 30px; display: block">
              <Option :value="1">文本</Option>
              <Option :value="2"
                      v-if="markIcon.length>0">图标</Option>
              <Option :value="3">热点</Option>
            </Select>
          </div>
          <div v-show="markType == 2">
            <p>图标选择</p>
            <div class="icon-rail mark-rail"
                 v-show="markType == 2">
              <div v-for="(m, idx) in markIcon"
                   :style="{
                  background:
                    markIconType === idx
                      ? 'rgba(120,120,120,0.3)'
                      : 'transparent',
                }"
                   :key="idx">
                <img :src="m"
                     @click="changeMarkIcon(idx)"
                     alt="" />
              </div>
            </div>
            <!-- <div class="icon-rail"
                 v-show="markType==3"
                 style="height: 50px;">

            </div> -->
          </div>
          <div>
            <p>文本信息</p>
            <div class="icon-rail">
              <Input v-model="markText"
                     type="textarea"
                     :autosize="{ minRows: 4, maxRows: 4 }"
                     placeholder="输入文字信息" />
            </div>
            <!-- <p v-show="markType!=2">文字大小</p>
            <Slider v-model="fontSize"
                    v-show="markType!=2"
                    show-tip='never'
                    style="width:230px;margin:10px auto 30px;"
                    :max='20'
                    :min='12'></Slider> -->
          </div>
          <div v-show="markType == 3">
            <p>目标场景</p>
            <Select v-model="panoramaImgId"
                    style="width: 230px; margin: 10px auto 30px; display: block"
                    placeholder="选择目标场景">
              <Option v-for="item in categoryList.filter((el) => el.id != id)"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
          </div>
        </div>
        <div class="btns"
             v-show="onMarks">
          <div style="background: #286dfa; color: #fff"
               @click="completeMark">
            完成
          </div>
          <div @click="deleteMark">删除</div>
        </div>
      </aside>
    </main>
    <Modal v-model="delModel"
           @on-ok="delsure()"
           :loading="delLoading"
           title="确认删除?"
           :wdith="200">
      <p>
        <Icon type="md-alert"
              style="color: #ff9900"
              size="20" />是否删除该全景图？
      </p>
    </Modal>
    <Modal v-model="shareModel"
           width="450"
           title="分享链接">
      <div class="shareUrl">
        <p style="margin-bottom: 20px">
          <span class="share-span">链接：{{ shareUrl }}</span>
          <Icon type="ios-copy"
                title="点击复制"
                style="cursor: pointer"
                size="20"
                @click="copy" />
        </p>
        <vue-qr :text="shareUrl"
                colorDark="#000"
                colorLight="#fff"
                :margin="5"
                style="vertical-align: bottom; margin-right: 20px"
                :size="120"></vue-qr>
        <span class="share-span"
              @click="bigqr = true"
              style="cursor: pointer">点击放大</span>
        <span class="share-note"
              @click="showNote"
              v-if="$CSXF">短信分享</span>
      </div>
    </Modal>
    <Modal v-model="shareNote">
      <span>手机号：</span>
      <Input v-model="phoneNum"
             class="note-input"
             placeholder="请输入手机号">
      </Input>
      <div slot="footer"
           class="footer">
        <Button type="primary"
                @click="compile_ok">确认</Button>
        <Button @click="closeModl">取消</Button>
      </div>
    </Modal>
    <div class="qr"
         v-show="bigqr">
      <Icon type="md-close-circle"
            @click="bigqr = false"
            size="40" />
      <vue-qr :text="shareUrl"
              colorDark="#000"
              colorLight="#fff"
              style="display: block"
              :size="400"></vue-qr>
    </div>
  </div>
</template>

<script>
import { Viewer, Animation } from 'photo-sphere-viewer';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';
import Api from '@/utils/api.js';
import {
  PanoramawebUpdate,
  PanoramawebDetail,
  UploadFile,
  addPlusImage,
  PanoramawebDel,
  ModelIconList,
} from '@/utils/javaApi.js';
import vueQr from 'vue-qr';

// import pinRed from '@/assets/pin-red.png';
export default {
  name: 'EdtiPanorama',
  components: { vueQr },
  props: [],

  data() {
    return {
      percent: 0,
      isFinish: false,
      isFinish2: false,
      caption: '',
      panoramaInfo: {},
      categoryList: [], // 要跳转的图片集合

      id: 0,
      imgPath: '',
      viewer: null,
      markersPlugin: null,
      autorotatePlugin: null,
      markers: [],
      markerConfig: {},
      point: {},
      markerTypeMark: '',

      tempPoint: {},

      loadingImg: require('@/assets/photosphere-logo.gif'),
      pinred: require('@/assets/pin-red.png'),

      inset: false, // 嵌入模式

      markType: 1, // 标注类型
      markIcon: [
        require('@/assets/img/Marker/Panorama_mark_1.png'),
        require('@/assets/img/Marker/Panorama_mark_2.png'),
        require('@/assets/img/Marker/Panorama_mark_3.png'),
        require('@/assets/img/Marker/Panorama_mark_4.png'),
        require('@/assets/img/Marker/Panorama_mark_5.png'),
        require('@/assets/img/Marker/Panorama_mark_6.png'),
      ],
      guidICon: [require('@/assets/guide.gif')],
      markIconType: 0,
      markText: '默认文本',
      fontSize: 16, // 文本大小
      onMarks: false,
      markID: '16',
      panoramaImgPath: '', // 选中的热点图
      panoramaImgId: '', // 选中的热点图id
      MediaUrl: '',
      upLoadFileData: {
        dir: '全景图',
        token: '',
      },
      editTitle: false, // 名称编辑模式
      justWatch: 1, // 仅查看模式

      shareModel: false,
      shareUrl: '',
      sharebaseUrl: '',
      bigqr: false,
      delModel: false,
      delLoading: true,
      shareNote: false, //短信分享弹窗
      phoneNum: '', //分享手机号
    };
  },
  created() {
    this.justWatch = this.$route.query.watch;
    this.id = this.$route.query.id;
    this.MediaUrl = UploadFile();
    this.sharebaseUrl = this.$mainHost + '/PSVshare.html?id=';
    this.upLoadFileData.token = sessionStorage.getItem('token');
  },

  mounted() {
    this.getDetail();
    this.getIconList();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    copy() {
      //   console.log(params.row.apk_url);
      let oInput = document.createElement('input');
      oInput.value = this.shareUrl;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      oInput.style.display = 'none';
      this.$Message.success('已复制到剪切板');
      document.body.removeChild(oInput);
    },

    formatMarke(el) {
      switch (el.markerTypeMark) {
        case '1':
          el.style = {
            padding: '5px',
            color: '#fff',
            fontSize: '16px',
            fontFamily: 'Helvetica, sans-serif',
            background: 'rgba(0,0,0,0.46)',
            border: '1px solid #f5ff00',
            borderRadius: '4px',
          };
          delete el.circle;
          delete el.height;
          delete el.tooltip;
          delete el.width;
          delete el.panoramaImgPath;
          delete el.panoramaImgId;
          delete el.name;
          delete el.image;
          delete el.html2;
          delete el.content;
          break;
        case '2':
          //   el.image = this.markIcon[Number(el.markIconType)];
          el.image = el.image; // 直接读取保存的base64图片,避免图标删除后产生的影响
          el.anchor = 'bottom center';
          delete el.circle;
          delete el.content;
          delete el.panoramaImgPath;
          delete el.panoramaImgId;
          delete el.name;
          delete el.html;
          delete el.html2;
          break;
        case '3':
          delete el.circle;
          delete el.content;
          delete el.height;
          delete el.html;
          delete el.image;
          delete el.html;
          el.html =
            '<img class="guideIcon" src="' + this.guidICon[0] + '">' + el.html2;
          el.style = {
            // padding: '5px',
            color: '#fff',
            fontSize: this.fontSize + 'px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center',
            // background: 'rgba(0,0,0,0.46)',
            // borderBottom: '1px solid #e94242',
          };
        default:
          break;
      }
      this.markersPlugin.addMarker(el);
    },
    getIconList() {
      this.$post(ModelIconList(), {
        team_id: 1,
        type: '2d',
        is_share: 2,
      }).then((res) => {
        this.markIcon = [];
        res.data.cloud_image_list.forEach((element) => {
          this.markIcon.push(element.path);
        });
      });
    },
    async getDetail() {
      let res = await this.$post(PanoramawebDetail(), { panorama_id: this.id });
      if (res.code === 1) {
        console.log('res=====',res);
        let centerPoint = [res.data[0].lng,res.data[0].lat]
        this.$store.commit('SET_PitchMapCenter',centerPoint)
        // res.data返回了全部场景,需筛选当前
        if (res.data.length == 0) {
          //   this.$Message.error('该场景已被删除');
          return false;
        }
        let result = res.data.filter((el) => el.id == this.id)[0];
        this.imgPath = result.panoramaImgPath;
        this.caption = result.name;
        this.shareUrl = this.sharebaseUrl + this.id;
        this.init();
        this.markers = result.markers;
        // console.log('markers', this.markers);
        this.viewer.once('ready', this.setPoints);
        this.categoryList = res.data;
        // this.categoryList = res.data.filter((el) => el.pid != 0);
      } else {
        this.$Message.error(res.msg_customer);
      }
    },
    setPoints() {
      this.markers.forEach((el, index) => {
        this.formatMarke(el);
      });
    },

    async saveBtn() {
      if (this.caption == '') {
        this.$Message.warning('全景场景名不能为空');
        return false;
      } else {
        let params = {
          id: this.id,
          markers: JSON.stringify(this.markers),
          name: this.caption,
        };
        let res = await this.$post(PanoramawebUpdate(), params);
        if (res.code === 1) {
          this.$Message.success(res.msg_customer);
        } else {
          this.$Message.error(res.msg_customer);
        }
      }
    },
    // 切换标注图标
    changeMarkIcon(idx) {
      this.markIconType = idx;
      if (this.onMarks) {
        this.markersPlugin.updateMarker({
          id: this.markID,
          tooltip: this.markText,
          image: this.markIcon[this.markIconType],
        });
      }
    },
    // 删除全景
    del() {
      this.delModel = true;
    },
    delsure() {
      this.$post(PanoramawebDel(), {
        panorama_id: this.id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.id = this.$route.query.id;
            this.switchView(this.id);
          } else {
            this.$Message.error('系统错误，请联系管理员');
          }
          this.delLoading = false;
          this.delModel = false;
        })
        .catch((err) => {
          this.delLoading = false;
          this.$Message.error(err);
        });
    },
    /**
     * 上传
     */
    // 上传格式错误
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '请选择jpg或png格式文件',
      });
    },
    handleMaxSize() {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于10M.',
      });
    },
    // 上传失败
    addMediaError(error) {
      this.$Message.error(error);
    },
    // 开始文件上传
    onProgress(event, file) {
      this.isFinish = true;

      // event.target.onprogress = (event) => {
      file.showProgress = true;
      let uploadPercent = parseFloat(
        ((event.loaded / event.total) * 100).toFixed(2)
      );
      file.percentage = uploadPercent;
      this.percent = uploadPercent;
      if (uploadPercent >= 100) {
        this.isFinish = false;
        this.isFinish2 = true;
      }
      // };
    },
    // 上传成功
    addMediaSuccess(response, file) {
      /**
       * 添加全景
       * 刷新页面
       */
      if (response.code === 1) {
        this.$Message.info('上传成功');
        this.$post(addPlusImage(), {
          map_name: '新增全景图场景',
          p_id: this.$route.query.id,
          image_path: response.data.file_path[0],
          describe: '1',
        }).then((res) => {
          this.isFinish2 = false;
          this.reload(1);
        });
      } else {
        this.$Message.error(response.msg_customer);
      }
    },
    //
    // 名称编辑
    updateTitle() {
      /**
       * this.caption
       * this.id
       */
      if (this.caption == '') {
        this.$Message.warning('全景场景名不能为空');
      } else {
        this.editTitle = false;
      }
    },
    // 重新加载
    reload(n) {
      if (n == 1) {
        // this.markersPlugin.clearMarkers();
        // this.markers = [];
        // this.viewer.destroy();
        this.getDetail();
      }
      this.handleMarkerClick();
      this.onMarks = false;
      this.fontSize = 16;
      this.markText = '默认文本';
      this.panoramaImgId = '';
      this.markID = '';
      this.markIconType = 0;
      this.markType = 1;
      this.inset = false;
      this.viewer.off('click');
    },
    // 切换场景
    switchView(id) {
      /**
       * 初始化-如果地图上有点,也不保存
       */
      this.id = id;
      this.reload(1);
    },
    // 完成图标添加
    completeMark() {
      /**
       * 1.根据id修改标记 updateMarker
       * 2.更新markText或位置\缩放等参数
       * 3.更新标记数组
       * 4.关闭onMarks
       * 5.初始化配置
       */
      let i = this.markers.findIndex((el) => el.id == this.markID);
      console.log('this.markType', this.markType);
      if (this.markType == 1) {
        if (this.markText == '') {
          this.markText = '默认文本';
        }
        this.markersPlugin.updateMarker({
          id: this.markID,
          html: this.markText,
        });
        this.markers[i].html = this.markText;
      } else if (this.markType == 2) {
        this.markersPlugin.updateMarker({
          id: this.markID,
          tooltip: this.markText,
          image: this.markIcon[this.markIconType],
        });
        this.markers[i].tooltip = this.markText;
        this.markers[i].markIconType = this.markIconType;
      } else if (this.markType == 3) {
        if (this.panoramaImgId == '') {
          this.$Message.warning('未选择目标场景');
          return false;
        }
        this.markersPlugin.updateMarker({
          id: this.markID,
          html:
            '<img class="guideIcon" src="' +
            this.guidICon[this.markIconType] +
            '">' +
            this.markText,
        });
        this.markers[i].html2 = this.markText;
        this.markers[i].markIconType = this.markIconType;
        // this.markers[i].panoramaImgPath = this.panoramaImgPath;
        this.markers[i].panoramaImgId = this.panoramaImgId;
      }
      this.reload(2);
    },
    // 删除图标
    deleteMark() {
      /**
       * 1.根据id删除标记 removeMarker
       * 2.更新标记数组
       * 3.关闭onMarks
       * 4.初始化配置
       */
      let index = this.markers.findIndex((el) => el.id == this.markID);
      this.markers.splice(index, 1);
      this.markersPlugin.removeMarker(this.markID);

      this.reload(2);
    },
    // 选择列表中的标记
    selectMark(item) {
      if (this.justWatch == 2) {
        this.markersPlugin.gotoMarker(item.id, 1000);
      } else {
        this.markersPlugin.gotoMarker(item.id, 1000).then(() => {
          this.onMarks = true;
          this.inset = true;
        });
        this.markType = item.markerTypeMark;
        this.markID = item.id;
        if (this.markType == 1) {
          this.markText = item.html;
        } else if (this.markType == 2) {
          this.markText = item.tooltip;
          this.markIconType = item.markIconType;
        } else if (this.markType == 3) {
          this.markText = item.html2;
          this.markIconType = item.markIconType;
          this.panoramaImgId = item.panoramaImgId;
        }
      }
    },
    // 点击标注点
    handleMarkerClick() {
      this.markersPlugin.off('select-marker');
      this.markersPlugin.on('select-marker', (e, marker, data) => {
        // var a = this.viewer.getPosition();

        if (data.dblclick) {
          console.log(this.inset);
          if (this.justWatch == 1 && !this.inset) {
            this.onMarks = true;
            this.inset = true;
            console.log('点击的点', marker);
            this.markType = marker.config.markerTypeMark;
            this.markID = marker.config.id;
            if (this.markType == 1) {
              this.markText = marker.config.html;
            } else if (this.markType == 2) {
              this.markText = marker.config.tooltip.content;
              this.markIconType = marker.config.markIconType;
            } else if (this.markType == 3) {
              this.markText = marker.config.html2;
              this.markIconType = marker.config.markIconType;
              this.panoramaImgId = marker.config.panoramaImgId;
            }
            //   this.markerTypeMark = this.point.markerTypeMark;
            //   let i = this.markers.findIndex((el) => el.id == this.point.id);
            //   console.log('点击的点2', this.markers[i]);
          } else if (this.justWatch == 2) {
            if (marker.config.markerTypeMark == 3) {
              if (
                this.categoryList.some((item) => {
                  if (item.id == marker.config.panoramaImgId) {
                    return true;
                  } else {
                    return false;
                  }
                })
              ) {
                this.switchView(marker.config.panoramaImgId);
              } else {
                this.$Message.error('该场景已被删除');
                //   this.switchView(marker.config.panoramaImgId);
              }
            }
          }
        }
      });
    },

    handleViewerClick() {
      this.inset = true;
      let that = this;

      this.viewer.on('click', function (e, data) {
        if (that.onMarks || data.rightclick) {
          return false; // 未确认标注完成不打点
        }
        that.markID = new Date().getTime();
        let temp = '';
        that.onMarks = true; // 已打点的标识
        if (that.markText == '') {
          that.markText = '默认文本';
        }
        let point = {
          id: that.markID,
          longitude: data.longitude,
          latitude: data.latitude,
        };

        if (that.markType == 1) {
          // 文本标注
          point.markerTypeMark = 1;
          point.html = that.markText;
          point.anchor = 'center center';
          point.style = {
            padding: '5px',
            color: '#fff',
            fontSize: that.fontSize + 'px',
            fontFamily: 'Helvetica, sans-serif',
            background: 'rgba(0,0,0,0.46)',
            border: '1px solid #f5ff00',
            borderRadius: '4px',
          };
          temp = JSON.parse(JSON.stringify(point));
          delete temp.style;
          //   console.log('打点后', that.tempPoint);
        }

        // if (that.markerTypeMark == 'guide') {
        //   that.point.markerTypeMark = 'guide';
        //   that.point.html = that.formValidate.html + that.guidICon;

        //   that.point.style = {
        //     maxWidth: '320px',
        //     color: '#fff',
        //     fontSize: '20px',
        //     fontFamily: 'Helvetica, sans-serif',
        //     textAlign: 'center',
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //   };
        //   that.tempPoint = JSON.parse(JSON.stringify(that.point));
        //   delete that.tempPoint.style;
        //   delete that.tempPoint.anchor;
        //   console.log(that.formValidate);
        //   that.tempPoint.html = that.formValidate.html;
        //   that.tempPoint.html2 = that.formValidate.html;
        //   console.log(that.tempPoint);
        // }
        // 图标标注
        else if (that.markType == 2) {
          //   that.point.image = this.pinRed;
          point.markerTypeMark = 2;
          point.image = that.markIcon[that.markIconType];
          point.markIconType = that.markIconType;
          point.width = 32;
          point.height = 32;
          point.anchor = 'bottom center';
          point.tooltip = that.markText;
          temp = JSON.parse(JSON.stringify(point));
        } else if (that.markType == 3) {
          // 导航图标
          point.markerTypeMark = 3;
          point.anchor = 'center center';
          point.html2 = that.markText;
          point.markIconType = that.markIconType;
          point.html =
            '<img class="guideIcon" src="' +
            that.guidICon[that.markIconType] +
            '">' +
            that.markText;
          point.style = {
            // padding: '5px',
            color: '#fff',
            fontSize: that.fontSize + 'px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center',
            // background: 'rgba(0,0,0,0.46)',
            // borderBottom: '1px solid #e94242',
          };
          //   point.panoramaImgPath = that.panoramaImgPath;
          point.panoramaImgId = that.panoramaImgId;
          temp = JSON.parse(JSON.stringify(point));
          delete temp.style;
        }

        that.markersPlugin.addMarker(point);
        that.markers.push(temp);
        //   console.log(555, that.tempPoint);
        //   console.log(666, that.markers);
        // console.log(point);
      });
    },
    init() {
      if (this.viewer) {
        this.markersPlugin.clearMarkers();
        this.markers = [];
        this.viewer.destroy();
      }
      this.viewer = new Viewer({
        container: 'viewer',
        panorama: this.imgPath,
        loadingImg: this.loadingImg,
        touchmoveTwoFingers: true,
        // mousewheelCtrlKey: true,
        // caption: this.caption + '<b>&copy; bigDog</b>',
        defaultLong: '100deg',
        plugins: [MarkersPlugin],
      });

      this.markersPlugin = this.viewer.getPlugin(MarkersPlugin);

      this.handleMarkerClick();
    },

    //提交手机号
    compile_ok() {
      if (this.phoneNum) {
        const pattern =
          /[a-zA-Z`%~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！!@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
        let repNum = this.phoneNum.replace(pattern, ',');
        let partitionNum = repNum.split(',');
        this.shareNote = false;
        let params = {
          userIdList: partitionNum,
          livePath: this.shareUrl,
        };
        this.$post(Api.shareNote(), params);
      } else {
        this.$Message.error('请输入手机号');
      }
    },
    //关闭分享弹窗
    closeModl() {
      this.shareNote = false;
    },
    //关闭分享弹窗，打开短信风向弹窗
    showNote() {
      this.shareModel = false;
      this.shareNote = true;
    },
  },
};
</script> 

<style lang="scss" scoped>
header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $xf_hei1;
  color: $white;
  padding: 0 20px;
  border-bottom: 1px solid $black;
  span {
    margin-right: 15px;
    display: inline-block;
    width: 79px;
    height: 23px;
    text-align: center;
    line-height: 23px;
    color: $white;
    background: $xf_hui7;
    cursor: pointer;
  }
}
main {
  display: flex;
  height: calc(100vh - 100px);
  color: $white;
  background-color: $hui3;
  //   nav {
  //     width: 64px;
  //     border-right: 1px solid $black;
  //   }
  section {
    // width: calc(100% - 324px);
    width: calc(100% - 260px);
    background-color: $xf_hei6;
    .title {
      margin: 10px;
      width: calc(100% - 20px);
      background-color: $table_td_2;
      text-align: center;
      height: 38px;
      line-height: 38px;
    }
    footer {
      background-color: $hui3;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;

      > div {
        height: 100px;
        // display: flex;
        align-items: center;
        display: -webkit-box;
        padding: 0 13px;

        div {
          width: 70px;
          height: 70px;
          margin-right: 18px;
          cursor: pointer;
        }
        .panorama-list {
          overflow: hidden;
          img {
            width: 70px;
            height: 70px;
          }
        }
        .panorama-list-active {
          box-shadow: 0 0 0 3px #00a7ff;
        }
        .upload {
          padding-top: 8px;
          text-align: center;
          border: 1px dashed $xf_hui3;
        }
      }
    }
    footer::-webkit-scrollbar {
      /*滚动条整体样式*/
      //   width: 8px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 8px;

      //   background-color: #f00;
    }
    footer::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 4px;
      background: #929292;
    }
  }
  aside {
    width: 264px;
    position: relative;
    border-left: 1px solid $black;
    background-color: $xf_hei6;
    overflow-y: auto;
    .inset-list {
      .inset-list-btnbox {
        background: $xf_hei6;
        padding: 14px 0;
        .start-inset {
          width: 202px;
          height: 30px;
          line-height: 30px;
          margin: 0 auto;
          cursor: pointer;
        }
      }
      .inset-lists {
        > p {
          padding: 12px;
        }
        div {
          background-color: $xf_hei3;
          color: $white;
          height: 40px;
          line-height: 40px;
          margin-bottom: 6px;
          cursor: pointer;
          img {
            display: inline-block;
            margin: 0 17px;
            vertical-align: middle;
          }
          span {
            display: inline-block;
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            white-space: nowrap;
          }
        }
      }
    }
    .inset {
      p {
        width: 230px;
        margin: 0 auto;
      }
      .icon-rail {
        margin: 10px auto 30px;
        width: 230px;

        background: $xf_hei1;
        border: 1px solid $white;
        border-radius: 3px;
      }
      .mark-rail {
        height: 140px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        > div {
          width: 60px;
          height: 40px;
          margin: 10px 0;
          img {
            width: 40px;
            margin: 0 10px;
            cursor: pointer;
          }
        }
      }
    }
    .btns {
      position: absolute;
      width: 100%;
      bottom: 21px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      div {
        width: 110px;
        height: 34px;
        border: 1px solid #286dfa;
        color: #286dfa;
        border-radius: 2px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
#viewer {
  width: calc(100% - 20px);
  margin: 0 10px;
  height: calc(100% - 160px);
  /deep/ .guideIcon {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  /deep/ .psv-panel-content {
    padding: 10px 20px 10px 10px;
    padding-right: 80px;
    line-height: 2em;
  }
  /deep/ .psv-markers-list-button {
    display: none;
  }
}
.btn {
  background: #0773ca;
  color: #fff;
  text-align: center;
}
.shareUrl {
  .share-span {
    display: inline-block;
    border: 1px solid $xf_hui7;
    padding: 5px;
    background-color: $xf_hui6;
    margin-right: 12px;
  }
  margin-bottom: 20px;
  .share-note {
    display: flex;
    display: inline;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 5px;
    border: 1px solid $xf_hui7;
    color: #c9caca;
    background-color: $xf_hui6;
    cursor: pointer;
  }
}
.footer {
  text-align: right;
  // button {
  //   // width: 88px;
  // }
  button:last-child {
    background-color: $xf_hui7;
    color: $font_color_1;
  }
  button:last-child:hover {
    background-color: $xf_hui7_hover;
  }
}
.qr {
  position: fixed;
  width: 100vw;
  height: 100vw;
  top: 0;
  left: 0;
  z-index: 1800;
  background-color: rgba(0, 0, 0, 0.7);
  img {
    margin: 200px auto 0;
  }
  i {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}
</style>